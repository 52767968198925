import React, { useEffect, useState } from 'react';
import { BrowserRouter, Link, Route, Routes, useLocation } from 'react-router-dom';
import appConfig from "./configuration"; // Assuming the correct path to your configuration file
import { getDatabase, ref, onValue } from "firebase/database";

import './App.css';
import Landing from './Landing';
import Sights from './pages/Sights/Sights';
import DigitalResources from './pages/DigitalResources/DigitalResources';
import Project from './pages/About/Project';
import Home from './pages/Home/Home';
import Sight from './pages/Sights/Sight';
import Themes from './pages/Sights/Themes';
import Contact from './pages/Contact/Contact';
import {News, New} from './pages/News/News';

import menuIcon from './assets/img/menu.png'
import leftArrow from './assets/img/left-arrow.png'
import NavBar from './components/Accordion/Accordion';
import Idea from './pages/Idea/Idea';
import Team from './pages/Team/Team';
import SideMenu from './components/SideMenu/SideMenu';
import PhotoAlbums from './pages/PhotoAlbum/PhotoAlbums';
import CalendarPage from './pages/Calendar/Calendar';
import OnFocus from './pages/OnFocus/OnFocus';
import PhotoAlbum from './pages/PhotoAlbum/PhotoAlbum';
import Links from './pages/Links/Links';
import Papers from './pages/Papers/Papers';
import Reports from './pages/Reports/Reports';
import Press from './pages/Press/Press';
import Catalogue from './pages/Catalogue/Catalogue';

const Header = () => {
	const [openMenuStyle, setOpenMenuStyle] = useState({});
	const [openMenuIcon, setOpenMenuIcon] = useState(menuIcon);
	const [data, setData] = useState([]);
	const location = useLocation();
	const [currentLocation, setCurrentLocation] = useState(location.pathname);
	const [collapseAllSubmenus, setCollapseAllSubmenus] = useState(false)

	useEffect(() => {
		// Initialize the Firebase database with the provided configuration
		const database = getDatabase(appConfig);

		// Reference to the specific collection in the database
		const collectionRef = ref(database, "objects");
	
		// Function to fetch data from the database
		const fetchData = () => {
			// Listen for changes in the collection
			onValue(collectionRef, (snapshot) => {
			const dataItem = snapshot.val();
	
			// Check if dataItem exists
			if (dataItem) {
				// Convert the object values into an array
				const displayItem = Object.values(dataItem);
				
				setData(displayItem);
			}
			});
		};
	
		// Fetch data when the component mounts
		fetchData();
	}, [])

	const openMenu = () => {
		setOpenMenuStyle({
			left: 0,
		})
		setOpenMenuIcon(
			leftArrow
		)
	}

	const closeMenu = () => {
		setOpenMenuStyle({
			left: '-300px'
		})
		setOpenMenuIcon(
			menuIcon
		)
	}

	const toggleMenu = () => {
		if (openMenuStyle.left === 0) { // opened
			closeMenu();
		}
		else { // closed
			openMenu();
		}
	}

	useEffect(() => {
		if (currentLocation !== location.pathname) {
			setCurrentLocation(location.pathname);
			closeMenu();
		}
	}, [location])

	return (
		<header className="App-header">
			<div className="Menu-icon-wrapper">
				<img className="Menu-icon" onClick={toggleMenu} src={openMenuIcon}/>
			</div>
			<div className="Menu" style={openMenuStyle}>
				<SideMenu collapseAllSubmenus={collapseAllSubmenus}/>
			</div>
			<div className="App-header-nav-items">
				<NavBar collapseAllSubmenus={collapseAllSubmenus} setCollapseAllSubmenus={setCollapseAllSubmenus}/>
			</div>
			<Link className="App-header-logo" to={"/"}><img src={require("./assets/img/logo/logo1.png")} /></Link>
		</header>
	)
}

const Footer = () => {
	return (
		<div className="App-footer">
			<div className="Sponsors-logos">
				<a href='https://bnsf.bg/wps/portal/fond-izsledvaniq/home' target="_blank"><img src={require("./assets/img/sponsors/1.png")} style={{ width: "10vw", marginBottom: "1vw"}}/></a>
				<a href='https://www.balkanstudies.bg/bg/' target="_blank"><img src={require("./assets/img/sponsors/2.png")}/></a>
				<a href='https://www.bas.bg/' target="_blank"><img src={require("./assets/img/sponsors/3.png")}/></a>
			</div>
			<div className="Own-logo">
				<img src={require("./assets/img/logo/logo1.png")}></img>
				<div style={{ display: "flex", flexDirection: "row" }}>
				<p className="Copyright-text">© Copyright, MEDIA CulAge, 2024</p>
				<a href="https://www.youtube.com/@MEDIACulAge" target="_blank">
					<img src={require("./assets/img/youtube-icon-small.png")} style={{ width: "3vw", margin: "0 1vmin" }}/>
				</a>
				</div>
			</div>
		</div>
	)
}
const App = () => {

	return (
		<div className="App">
			<BrowserRouter>
			<Header/>
			<Routes>
				<Route path="/" element={<Landing/>}/>
				<Route path="/home" element={<Home/>}/>
				<Route exact path="/themes/:type/:themeId" element={<Themes/>}/>
				<Route exact path="/themes/:type/:themeId/:sightsId" element={<Sights/>}/>
				<Route path="/sights/:sightId" element={<Sight/>}/>
				<Route path="/on-focus" element={<OnFocus/>}/>
				<Route path="/digital-resources" element={<DigitalResources/>}/>
				<Route path="/project" element={<Project/>}/>
				<Route path="/papers" element={<Papers/>}/>
				<Route path="/reports" element={<Reports/>}/>
				<Route path="/press" element={<Press/>}/>
				<Route path="/links" element={<Links/>}/>
				<Route path="/news" element={<News/>}/>
				<Route path="/news/:newId" element={<New/>}/>
				<Route path="/idea" element={<Idea/>}/>
				<Route path="/team" element={<Team/>}/>
				<Route path="/contact" element={<Contact/>}/>
				<Route path="/albums/:type/:albumsCategoryId" element={<PhotoAlbums/>}/>
				<Route exact path="/albums/:albumId" element={<PhotoAlbum/>}/>
				<Route path="/catalogue" element={<Catalogue/>}/>
				<Route path="/calendar" element={<CalendarPage/>}/>
			</Routes>
			<Footer/>
			</BrowserRouter>
		</div>
	);
}

export default App;
