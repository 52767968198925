import React, { useEffect, useState } from 'react';
import './News.css';
import news from './newsData';
import { Link, useParams } from 'react-router-dom';

const New = () => {
	const { newId } = useParams();
	const [newData, setNewData] = useState({})
	
	useEffect(() => {
		const newData = news.find(newIt => newIt.id == newId);
		setNewData(newData);
		
	}, [newId]);
	
	return (
		<div className="New">
			<h1 className="NewHeader">{newData?.title}</h1>
			{newData.paragraphs?.map(paragraph => (
				<p className="NewParagraph">{paragraph}</p>
			))}
		</div>
	)
}

const News = () => {
	return (
		<div className="News">
			<h1>Новини</h1>
			<ul className="NewsList">
				{news.map(newData => (
					<li className="NewsListItem">
						<Link to={`/news/${newData.id}`}>
							<h2 className="NewsTitle">{newData.title}</h2>
						</Link>
						<p className="NewsDescription">{newData.paragraphs[0]}</p>
					</li>
				))}
			</ul>
		</div>
	)
}

export { News, New };