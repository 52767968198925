import React from 'react';
import './Contact.css';

const Contact = () => {
	return (
		<div className="ContactPage">
			<h1>Контакти:</h1>
			<div className="Contacts">
				<div className="ContactLeft">
					<h2>Гл. ас. д-р Стела Стефанова</h2>
					<h3>Ръководител на проекта</h3>
					<div className="ContactInfo">
						<img className="ContactIcon" width="13px" src={require("../../assets/img/location.png")}/>
						<p>Институт за балканистика с Център по тракология – БАН</p>
					</div>
					<div className="ContactInfo">
						<img className="ContactIcon" width="20px" src={require("../../assets/img/email.png")}/>
						<p>stela.stefanova@balkanstudies.bg</p>
					</div>
				</div>
				<div className="ContactRight">
					<div className="ContactImage" style={{ backgroundImage: 'url(/reports/stela.jpg)'}}/>
				</div>
			</div>
		</div>
	)
}

export default Contact;