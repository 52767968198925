import { useState } from 'react'
import './App.css'
import Map from './components/Map/Map'
import CalendarComponent from './components/Calendar/Calendar'

const Home = () => {
	const [mapReady, setMapReady] = useState(false)

	const onMarkerClick = () => {
		window.location = "/digital-resources"
	}
	const onGoogleApiLoaded = ({ map, maps }) => {
		console.log("called");
		setMapReady(true)
	}
	return (
		<div className="Home">
		<div className="Landing">
			<img className="Landing-image-logo" src={require("./assets/img/logo/logo2.png")}></img>
			{/* <h1>DEVELOPING A MODEL FOR AN INTEGRATED DIGITAL PRESENTATION OF THE CULTURAL HERITAGE</h1>
			<h2>(ON THE EXAMPLE OF STARA ZAGORA DISTRICT)</h2>
			<h1>РАЗРАБОТВАНЕ НА МОДЕЛ ЗА ИНТЕГРИРАНО ДИГИТАЛНО ПРЕДСТАВЯНЕ НА КУЛТУРНОТО НАСЛЕДСТВО</h1>
			<h2>(НА ПРИМЕРА НА ОБЛАСТ СТАРА ЗАГОРА)</h2> */}
			<div className="Landing-video-container">
				<video className="Landing-video" autoPlay={true} loop={true} muted={true}>
					<source src={require("./assets/video/landing.mp4")}/>
				</video>
			</div>
		</div>
		{/* <div className="Keypoints">
			<ul className="Keypoints-list">
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={idea}></img>
						<h2>Идеята</h2>
						<p>Кратко описание за идеята.</p>
					</div>
				</li>
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={team}></img>
						<h2>Екипът</h2>
						<p>Кратко представяне на екипа.</p>
					</div>
				</li>
				<li className="Keypoints-list-item">
					<div className="Keypoint">
						<img src={results}></img>
						<h2>Резултати</h2>
						<p>Кратко представяне на резултатите.</p>
					</div>
				</li>
			</ul>
		</div> */}
		<Map/>
		<div style={{ zIndex: 0 }}>
			<CalendarComponent/>
		</div>
		</div>
	)
}

export default Home;